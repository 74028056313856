import type { FC } from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { pdf } from "@react-pdf/renderer";

import { isDevEnv } from "../../../app.config";
import { PageWrapper } from "../../../layouts/PageWrapper";
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { Button } from "../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { TextFieldController } from "../../../components/common/TextFieldController/TextFieldController.component";
import { PDFGenerator } from "../../../components/Reports/PDFGenerator/PDFGenerator.component";

export const PDFToolPage: FC = () => {
  const [loading, setLoading] = useState(false)
  const form = useForm<{ message: string }>({
    defaultValues: {
      message: "",
    },
    mode: "all",
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)
    try {
      const blob = await pdf((
        <PDFGenerator data={JSON.parse(values.message)} />
      )).toBlob();
      const url = URL.createObjectURL(blob)

      setLoading(false)
      window.open(url)
    } catch (err) {
      setLoading(false)
    }
  })

  if (!isDevEnv) {
    return <Navigate replace to={RouteConfig.DASHBOARD.fullPath} />
  }

  return (
    <PageWrapper title="Paste your data">
      <FormProvider {...form}>
        <Box
          component='form'
          onSubmit={handleSubmit}
        >
          <TextFieldController
            name="message"
            multiline
            rows={25}
          />

          <Button
            type="submit"
            size="small"
            endIcon={loading ? <ButtonLoader /> : null}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            Create
          </Button>
        </Box>
      </FormProvider>
    </PageWrapper>
  )
}