export const RouteConfig = {
  LOGIN: {
    path: "login",
    fullPath: "/login"
  },
  FORGOT_PASSWORD: {
    path: "forgot-password",
    fullPath: "/forgot-password"
  },
  INVITATION: {
    path: "invitation",
    fullPath: "/invitation"
  },
  NEW_PASSWORD: {
    path: "new-password",
    fullPath: "/new-password"
  },
  DASHBOARD: {
    path: "",
    fullPath: "/"
  },
  LOCATIONS: {
    path: "locations/:locationId?",
    fullPath: "/locations/:locationId?"
  },
  EXPLORER: {
    path: "explorer",
    fullPath: "/explorer"
  },
  EXPLORER_ADVICE: {
    path: "advice",
    fullPath: "/explorer/advice"
  },
  SETTINGS: {
    path: "settings",
    fullPath: "/settings"
  },
  SETTINGS_LOCATIONS: {
    path: "locations",
    fullPath: "/settings/locations"
  },
  SETTINGS_USERS: {
    path: "users",
    fullPath: "/settings/users"
  },
  SETTINGS_PAYMENT_HISTORY: {
    path: "payment-history",
    fullPath: "/settings/payment-history"
  },
  SETTINGS_TERMS: {
    path: "terms",
    fullPath: "/settings/terms"
  },
  REPORTS: {
    path: "reports/:reportId?",
    fullPath: "/reports/:reportId?"
  },
  REPORTS_CREATE: {
    path: "reports/:reportId?/create",
    fullPath: "/reports/:reportId?/create"
  },
  SCENARIOS: {
    path: "scenarios/:scenarioId?",
    fullPath: "/scenarios/:scenarioId?"
  },
  SCENARIOS_SIMULATIONS: {
    path: "simulations",
    fullPath: "/scenarios/:scenarioId?/simulations"
  },
  SCENARIOS_SIMULATIONS_PREVIEW: {
    path: "simulations/:simulationId/preview",
    fullPath: "/scenarios/:scenarioId?/simulations/:simulationId/preview"
  },
  SCENARIOS_CALCULATOR: {
    path: "calculator",
    fullPath: "/scenarios/:scenarioId?/calculator"
  },
  VALUE_CHAIN: {
    path: "value-chain/:inputFormId?",
    fullPath: "/value-chain/:inputFormId?"
  },
  KNOWLEDGE_BASE: {
    path: "knowledge-base",
    fullPath: "/knowledge-base"
  },
  PROFILE: {
    path: "profile",
    fullPath: "/profile"
  },
  PDF_TOOL: {
    path: "pdf-tool",
    fullPath: "/pdf-tool"
  },
}